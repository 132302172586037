<template>
  <v-container>
    <v-form ref="updateForm">
      <v-switch v-model="eventActivated" :label="$t('Edition activée')" v-on:change="onUpdateEventActivated"/>
      <v-container v-if="currentEvent">
        <v-select :items="eventsList" v-model="currentEvent.id" item-value="id" item-text="nom"/>
        <v-switch v-model="ticketingActivated" :label="$t('Billetterie disponible')" v-on:change="onUpdateTicketing"/>
        <v-container v-if="ticketingActivated">
          <v-text-field v-model="currentEvent.ticketing.url" :label="$t('Lien de la billeterie')"
                        v-on:change="onUpdateTicketing"/>
          <v-date-picker v-model="picker"
                         :first-day-of-week="1"
                         locale="fr-FR"
                         v-on:change="onUpdateTicketing"/>
        </v-container>
      </v-container>
      <v-btn color="primary" @click="save">
        <v-icon>mdi-briefcase-download</v-icon>
        Sauver
      </v-btn>
    </v-form>
    <Loading :visible="isLoading"/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';

const servicePrefix = 'GlobalParams';

class ActiveEvent {
  constructor(data) {
    Object.assign(this, data);
    this.setTicketing(data.ticketing);
  }

  get isTicketingActivated() {
    return (this.ticketing && this.ticketing.url && this.ticketing.startDate)
  }

  get isEventActivated() {
    return !!this.id
  }

  setTicketing(ticketing = null) {
    this.ticketing = ticketing || {url: null, startDate: null};
  }
}

export default {
  name: 'ActiveEvent',
  servicePrefix,
  components: {
    Loading,
  },
  data() {
    return {
      item: null,
      currentEvent: null,
      eventActivated: false,
      ticketingActivated: false,
      picker: null
    }
  },
  created() {
    this.isLoading = false;
    this.loadEvents();
    this.retrieve(decodeURIComponent(this.$route.name))
        .then(() => {
          this.item = this.find(decodeURIComponent(this.$route.name));
          this.initializeCurrentEvent(this.item.value);
          this.eventActivated = this.currentEvent.isEventActivated;
          this.ticketingActivated = this.currentEvent.isTicketingActivated;
          if (this.ticketingActivated) {
            try {
              this.picker = new Date(this.currentEvent.ticketing.startDate).toISOString().substr(0, 10)
            } catch (e) {
              console.error(e);
              this.picker = null;
            }
          }
        })

  },
  computed: {
    ...mapFields('globalParam', {
      isLoading: 'isLoading'
    }),
    ...mapGetters({
      find: 'globalParam/find',
      eventsList: 'evenement/list',
    }),
  },
  methods: {
    ...mapActions('globalParam', {
      retrieve: 'load',
      update: 'update'
    }),
    ...mapActions('evenement', {
      loadEvents: 'fetchAll'
    }),
    onUpdateEventActivated() {
      this.currentEvent = this.eventActivated ? new ActiveEvent(this.item.value) : {};
    },
    initializeCurrentEvent(data) {
      this.currentEvent = new ActiveEvent(data);
    },
    onUpdateTicketing() {
      this.currentEvent.setTicketing(this.ticketingActivated ? this.item.value.ticketing : null);
    },
    save() {
      this.update(Object.assign(this.item, {'value': this.currentEvent}));
    }
  },
  watch: {
    picker: function () {
      this.currentEvent.ticketing.startDate = this.picker;
    }
  }
};
</script>
